import styled from 'styled-components';
import { Layout, Dropdown as AntdDropdown } from 'antd';

const { Sider, Header: AntdHeader } = Layout;

export const Container = styled(Layout)`
  min-height: 100vh;
`;

export const Logo = styled.div`
  padding: 10px 0;
  text-align: center;
  img {
    height: 40px;
  }
`;

export const SideMenu = styled(Sider)`
  /*
  -webkit-box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.1);
  */
  -webkit-box-shadow: 2px 0px 6px 0px rgb(0, 21, 41, 0.35);
  -moz-box-shadow: 2px 0px 6px 0px rgb(0, 21, 41, 0.35);
  box-shadow: 2px 0px 6px 0px rgb(0, 21, 41, 0.35);
  z-index: 1;
  ${Logo} {
    background-color: #002140;
  }
`;

export const Header = styled(AntdHeader)`
  background-color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  /*
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  */
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.1);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.1);
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.1);
  z-index: 0;
  height: 48px;
`;

export const Unfold = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: baseline;

  span {
    font-size: 18px;
    line-height: 48px;
    padding: 0 15px;
    cursor: pointer;
    transition: color 0.3s;
    display: flex;
    align-items: center;

    :hover {
      color: var(--pink);
    }
  }
`;

export const Profile = styled.div`
  margin: 0 24px;
  display: flex;
  align-items: center;

  .ant-avatar {
    margin: 0 8px;
  }
`;

export const Dropdown = styled(AntdDropdown)`
  line-height: 48px;
  display: flex;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  cursor: pointer;
`;

export const ComponentContainer = styled.div`
  overflow: hidden;
  /* background-color: #1f1f1f; */
  background: #f0f2f5;
`;

export const Mask = styled.div`
  /* background-color: rgba(0, 0, 0, 0.1);
  filter: alpha(opacity=50);
  min-height: 100vh;
  */
`;
