import styled from 'styled-components';

import { Card } from 'antd';

export const CustomCard = styled(Card)`
  /* box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48),
    0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2); */
  /* background-color: #1f1f1f; */
`;

export type CustomCardProps = ReturnType<typeof CustomCard>;
