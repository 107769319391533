import api from 'services/api';

import { LoginData, PostLoginResponse } from 'ts/login/types';

const LOGIN_FETCH_URL = 'pessoas/login';

export const postLogin = async (
  login: string,
  password: string,
): Promise<LoginData> => {
  try {
    const response = await api.post(LOGIN_FETCH_URL, {
      login,
      password,
    });

    const {
      status,
      data,
    }: { status: number; data: PostLoginResponse } = response;

    const { contas, message } = data;

    if (status === 204) return { error: 'Login e/ou senha inválidos.' };
    if (status === 500)
      return {
        error:
          'Ocorreu um erro ao logar. Por favor, tente novamente mais tarde.',
      };
    if (status !== 200) {
      return { error: message };
    }
    return { contas };
  } catch (err) {
    return { contas: [] };
  }
};
