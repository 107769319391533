import React, { FC } from 'react';
import { Breadcrumb } from 'antd';
import Link from 'components/link/index';

import { Header } from './styles';

const { Item } = Breadcrumb;

interface PageHeaderProps {
  title: string;
  subtitle?: string | null;
  breadcrumb: Array<{ name: string; path?: string }>;
  renderRight?: boolean;
}

const PageHeader: FC<PageHeaderProps> = ({
  breadcrumb,
  title,
  subtitle,
  renderRight,
}: PageHeaderProps) => {
  return (
    <header>
      <Header>
        <div
          className={`page-header__left ${
            renderRight && 'page-header__left--render-right'
          }`}
        >
          <div className="page-header__title">
            <b>{title}</b>
          </div>
          {subtitle && <p className="page-header__subtitle">{subtitle}</p>}
        </div>
        <div className="page-header__right">
          <div
            className={`page-header__breadcrumb ${
              renderRight && 'page-header__breadcrumb--render-right'
            }`}
          >
            {breadcrumb && (
              <Breadcrumb>
                {breadcrumb.map((route, index, arr) => (
                  <Item key={index}>
                    {!route.path ? (
                      route.name
                    ) : (
                      <Link to={route.path}>{route.name}</Link>
                    )}
                  </Item>
                ))}
              </Breadcrumb>
            )}
          </div>
          {renderRight}
        </div>
      </Header>
    </header>
  );
};

PageHeader.defaultProps = {
  subtitle: null,
  renderRight: false,
};

export default PageHeader;
