import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Modal, Checkbox, Input, Select, Form } from 'antd';

import { UserOutlined, LockOutlined } from '@ant-design/icons';

import {
  login as loginService,
  isAuthenticated,
  getContaUrl,
} from 'services/auth';

import { fetchLogin } from 'redux/modules/login/loginSlice';

import { RootState } from 'redux/rootReducer';
import { Conta } from 'ts/login/types';
import logo from 'assets/logo_2.png';

import {
  ModalHeader,
  ModalFooter,
  LoginButton,
  AccountsContainer,
} from './styles';

const { Option } = Select;

const { Password } = Input;

const Login: React.FC<RouteComponentProps> = ({
  history,
}: RouteComponentProps) => {
  const dispatch = useDispatch();

  // FORM
  const [form] = Form.useForm();

  const [password, setPassword] = useState<string>();

  const [login, setLogin] = useState<string>();

  const [remember, setRemember] = useState(false);
  //

  // SELECT ACCOUNT
  const [showModal, setShowModal] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<number>();

  const accounts = useSelector((state: RootState) => state.login.accounts);
  //

  // Auto login if rememberMe
  useEffect(() => {
    if (isAuthenticated({})) {
      history.push(`/${getContaUrl()}/home`);
    }
  }, []);
  //

  const onFinish = (): void => {
    if (login && password) dispatch(fetchLogin(login, password));
  };

  const handleSelectConta = useCallback(() => {
    if (selectedAccount !== undefined && accounts[selectedAccount]) {
      loginService(accounts[selectedAccount].auth.token, remember);
      history.push(`/${accounts[selectedAccount].auth.conta_url}/home`);
    }
  }, [accounts, history, selectedAccount, remember]);

  useEffect((): void => {
    if (accounts.length > 0) {
      if (accounts.length === 1) {
        setSelectedAccount(0);
      } else {
        setShowModal(true);
      }
    }
  }, [accounts]);

  useEffect((): void => {
    if (selectedAccount !== undefined && accounts.length === 1) {
      handleSelectConta();
    }
  }, [selectedAccount, accounts, handleSelectConta]);

  const closeModal = (): void => setShowModal(false);

  const handleChangeSelect = (account: number): void => {
    setSelectedAccount(account);
  };

  return (
    <Modal
      visible
      centered
      mask={false}
      footer={null}
      closable={false}
      bodyStyle={{ padding: '40px' }}
    >
      <ModalHeader>
        <img alt="Logo TeamHub" src={logo} />
      </ModalHeader>
      <Form
        layout="vertical"
        initialValues={{ rememberMe: false, password: '', login: '' }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="login"
          rules={[
            {
              required: true,
              message: 'Por favor, informe seu login.',
            },
          ]}
        >
          <Input
            onChange={(e): void => setLogin(e.target.value)}
            prefix={<UserOutlined />}
            placeholder="Login de colaborador"
            size="middle"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor, informe sua senha.',
            },
          ]}
        >
          <Password
            onChange={(e): void => setPassword(e.target.value)}
            prefix={<LockOutlined />}
            placeholder="Senha"
            size="middle"
          />
        </Form.Item>

        <ModalFooter>
          <Checkbox
            name="rememberMe"
            onChange={() => setRemember(!remember)}
            checked={remember}
          >
            Lembre-se de mim
          </Checkbox>
          <span>Esqueci minha senha</span>
        </ModalFooter>

        <Form.Item>
          <LoginButton type="primary" size="large" htmlType="submit">
            Entrar
          </LoginButton>
        </Form.Item>
      </Form>

      <Modal
        title="Selecione sua conta"
        visible={showModal}
        onOk={handleSelectConta}
        onCancel={closeModal}
      >
        <AccountsContainer>
          <span>Selecione a conta que você deseja entrar</span>
          <Select
            onChange={(value: number, option) => handleChangeSelect(value)}
            value={selectedAccount}
          >
            {accounts.map((account: Conta, index: number) => (
              <Option value={index} key={account.auth.conta_url}>
                {account.auth.conta_razaonome}
              </Option>
            ))}
          </Select>
        </AccountsContainer>
      </Modal>
    </Modal>
  );
};

export default withRouter(Login);
