import api from 'services/api';

import { PesquisasData, GetPesquisaResponse } from 'ts/pesquisas/types';

const GET_PESQUISAS_URL = '/pesquisa';

export const getPesquisas = async (): Promise<PesquisasData> => {
  try {
    const response = await api.get(GET_PESQUISAS_URL);

    const {
      status,
      data,
    }: { status: number; data: GetPesquisaResponse } = response;

    if (status === 204) return { pesquisas: [] };
    if (status === 500)
      throw new Error('Ocorreu um erro ao tentar listar pesquisas.');

    const { pesquisas, message } = data;
    if (status !== 200) {
      if (message) return { error: message };
      throw new Error('Unknown Error.');
    }

    return { pesquisas };
  } catch (err) {
    return { error: err.toString() };
  }
};
