/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from 'redux/store';

import Routes from 'routes';
import GlobalStyle from 'styles/global';

// React Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled Components css support
import { CSSProp } from 'styled-components';

interface MyTheme {}

declare module 'react' {
  interface Attributes {
    css?: CSSProp<{}>;
  }
}

function render(): void {
  ReactDOM.render(
    <Provider store={store}>
      <Routes />
      <GlobalStyle />
      <ToastContainer autoClose={3000} />
    </Provider>,
    document.getElementById('root'),
  );
}

render();

declare const module: any;

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./routes', render);
}
