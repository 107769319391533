import React, { FC } from 'react';

// Components
import PageHeader from 'components/PageHeader';

import { Container } from './styles';

interface ContentLayoutProps {
  children: JSX.Element;
  className?: string | null;
  header: {
    title: string;
    subtitle?: string;
    breadcrumb: Array<{ name: string; path?: string }>;
    renderRight?: boolean;
  };
}

const ContentLayout: FC<ContentLayoutProps> = ({
  children: Children,
  className,
  header,
}: ContentLayoutProps) => (
  <Container className={`${className || ''}`}>
    {header && (
      <div className="content-layout__header">
        <PageHeader
          title={header.title}
          subtitle={header.subtitle}
          breadcrumb={header.breadcrumb}
          renderRight={header.renderRight}
        />
      </div>
    )}
    <div className="content-layout__body">{Children}</div>
  </Container>
);

ContentLayout.defaultProps = {
  className: null,
};

export default ContentLayout;
