/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'redux/store';

import { PesquisasState, PesquisasSuccessData } from 'ts/pesquisas/types';
import { getPesquisas } from './api';

const initialState: PesquisasState = {
  pesquisas: [],
  loading: false,
  error: null,
};

const pesquisasSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    fetchPesquisasStart(state) {
      state.loading = true;
    },
    fetchPesquisasSuccess(state, action: PayloadAction<PesquisasSuccessData>) {
      const { pesquisas } = action.payload;
      state.error = null;
      state.pesquisas = pesquisas;
      state.loading = false;
    },
    fetchPesquisasError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchPesquisasStart,
  fetchPesquisasSuccess,
  fetchPesquisasError,
} = pesquisasSlice.actions;

export const fetchPesquisas = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchPesquisasStart());
    const data = await getPesquisas();
    const { pesquisas, error } = data;
    if (error) {
      dispatch(fetchPesquisasError(error));
      return;
    }
    if (pesquisas) dispatch(fetchPesquisasSuccess({ pesquisas }));
  } catch (err) {
    dispatch(fetchPesquisasError(err.toString()));
  }
};

export default pesquisasSlice.reducer;
