import React, { FC, useState } from 'react';

import { Layout, Menu, Avatar } from 'antd';

import { RouteComponentProps } from 'react-router-dom';

import {
  HomeOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';

import Link from 'components/link';

import { getContaUrl, logout, getPessoaNome } from 'services/auth';

import logo from 'assets/logo.png';
import logo_sm from 'assets/logo_sm.png';
import {
  SideMenu,
  Container,
  Logo,
  Header,
  Unfold,
  Profile,
  Dropdown,
  AvatarContainer,
  ComponentContainer,
  Mask,
} from './styles';

interface MainLayoutProps extends RouteComponentProps {
  component: FC<RouteComponentProps>;
  showSider?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({
  component: Component,
  showSider,
  history,
  match,
  location,
}: MainLayoutProps) => {
  const contaURL = getContaUrl();

  const [collapsed, setCollapsed] = useState(true);

  const toggleSider = (): void => setCollapsed(!collapsed);

  const handleLogout = (): void => {
    logout();
  };

  return (
    <Container>
      {showSider && (
        <SideMenu
          collapsed={collapsed}
          width={200}
          breakpoint="lg"
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <Logo>
            <img src={collapsed ? logo_sm : logo} alt="Logo TeamHub" />
          </Logo>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            defaultSelectedKeys={[`/${contaURL}/home`]}
          >
            <Menu.Item key={`/${contaURL}/home`}>
              <Link to="/home">
                <HomeOutlined />
                <span>Home</span>
              </Link>
            </Menu.Item>
          </Menu>
        </SideMenu>
      )}
      <Layout style={{ marginLeft: !collapsed ? '200px' : '80px' }}>
        <Header>
          <Unfold>
            {showSider && (
              <MenuUnfoldOutlined
                className="dashboard-layout__header__btn"
                onClick={toggleSider}
              />
            )}
          </Unfold>

          <Profile>
            <Dropdown
              className="dashboard-layout__header__dropdown"
              overlay={() => (
                <Menu>
                  <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
                </Menu>
              )}
            >
              <AvatarContainer
                tabIndex={-1}
                role="button"
                className="ant-dropdown-link"
                onClick={(e): void => e.preventDefault()}
                onKeyDown={() => true}
              >
                <Avatar
                  className="dashboard-layout__header__profile__avatar"
                  icon={<UserOutlined />}
                />
                <span>{getPessoaNome()}</span>
              </AvatarContainer>
            </Dropdown>
          </Profile>
        </Header>
        <ComponentContainer>
          <Mask>
            <Component history={history} match={match} location={location} />
          </Mask>
        </ComponentContainer>
      </Layout>
    </Container>
  );
};

MainLayout.defaultProps = {
  showSider: true,
};

export default MainLayout;
