import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

// Services
import { getContaUrl } from 'services/auth';

interface LinkProps {
  to: string;
  children: ReactNode;
}

const PrivateLink: FC<LinkProps> = ({ children, to }: LinkProps) => {
  const contaURL = getContaUrl();
  return <Link to={`/${contaURL}${to}`}>{children}</Link>;
};

export default PrivateLink;
