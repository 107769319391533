import styled from 'styled-components';

import { Button } from 'antd';

export const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 35px;

  img {
    max-width: 325px;
    width: 100%;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  span {
    font-size: 14px;
  }
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;

export const AccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    font-size: 14px;
    margin-bottom: 8px;
  }
`;
