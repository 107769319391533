import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { fetchPesquisas } from 'redux/modules/pesquisas/pesquisasSlice';

import { RootState } from 'redux/rootReducer';

import { Col, Row } from 'antd';
import { Pesquisa as PesquisaProps } from 'ts/pesquisas/types';

import { noPadding, noMargin } from 'styles/global';

import CustomCard from 'components/CustomCard';
import Pesquisa from './components/Pesquisa';

import {
  Container,
  PesquisasPendentesRow,
  PesquisasConcluidasRow,
} from './styles';

const Pesquisas: React.FC = () => {
  const dispatch = useDispatch();

  const pesquisas = useSelector(
    (state: RootState) => state.pesquisas.pesquisas,
  );

  const error = useSelector((state: RootState) => state.pesquisas.error);

  const [pesquisasPendentes, setPesquisasPendentes] = useState<
    Array<PesquisaProps>
  >([]);

  const [pesquisasConcluidas, setPesquisasCondluidas] = useState<
    Array<PesquisaProps>
  >([]);

  useEffect((): void => {
    dispatch(fetchPesquisas());
  }, [dispatch]);

  useEffect((): void => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect((): void => {
    if (pesquisas.length > 0) {
      setPesquisasPendentes(pesquisas.filter((p) => !p.data_entrega));
      setPesquisasCondluidas(pesquisas.filter((p) => p.data_entrega));
    }
  }, [pesquisas]);

  return (
    <Container>
      <Col span={24} style={noPadding}>
        <Row gutter={16} style={noMargin}>
          <Col xs={24} sm={24} style={noPadding}>
            <CustomCard title="Pesquisas pendentes :" bordered={false}>
              <PesquisasPendentesRow>
                {pesquisasPendentes.map((p) => (
                  <Pesquisa
                    tipo={p.tipo}
                    hash={p.hashacesso}
                    data_entrega={p.data_entrega}
                    data_solicitacao={p.data_solicitacao}
                    key={`${p.tipo}-${p.avalia_id}`}
                  />
                ))}
              </PesquisasPendentesRow>
            </CustomCard>
          </Col>
          <Col xs={24} sm={24} style={noPadding}>
            <CustomCard title="Pesquisas concluídas :" bordered={false}>
              <PesquisasConcluidasRow>
                {pesquisasConcluidas.map((p) => (
                  <Pesquisa
                    tipo={p.tipo}
                    hash={p.hashacesso}
                    data_entrega={p.data_entrega}
                    data_solicitacao={p.data_solicitacao}
                    key={`${p.tipo}-${p.avalia_id}`}
                  />
                ))}
              </PesquisasConcluidasRow>
            </CustomCard>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default withRouter(Pesquisas);
