/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';

import { CardProps } from 'antd/lib/card';

import { CustomCard as Card, CustomCardProps } from './styles';

const CustomCard: FC<CardProps> = (props: CardProps): CustomCardProps => {
  return <Card {...props} />;
};

export default CustomCard;
