import styled, { css } from 'styled-components';
import { darken, cssVar } from 'polished';

interface PesquisaContainerProps {
  respondido?: boolean;
  tipo?: string;
}

export const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
`;

export const Container = styled.div<PesquisaContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.2); */

  border: 4px solid
    ${(props) => {
      if (props.tipo) {
        switch (props.tipo.toUpperCase()) {
          case 'PESQUISA DE PERCEPÇÃO':
            return 'var(--purple)';
          case 'VALORES ORGANIZACIONAIS':
            return 'var(--orange)';
          default:
            break;
        }
      }
      return 'var(--pink)';
    }};

  border-width: 4px 1px 1px 1px;

  .ant-btn {
    margin-top: 12px;
    ${(props) => {
      if (props.tipo) {
        switch (props.tipo.toUpperCase()) {
          case 'PERFIL COMPORTAMENTAL':
            return css`
              :hover {
                background-color: ${darken(0.1, cssVar('--pink').toString())};
              }
            `;
          case 'PESQUISA DE PERCEPÇÃO':
            return css`
              background: var(--purple);
              border-color: var(--purple);
              :hover {
                background-color: ${darken(0.1, cssVar('--purple').toString())};
              }
            `;
          case 'VALORES ORGANIZACIONAIS':
            return css`
              background: var(--orange);
              border-color: var(--orange);
              :hover {
                background-color: ${darken(0.1, cssVar('--orange').toString())};
              }
            `;
          default:
            return '';
        }
      }
      return '';
    }}
  }

  .tipo {
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .data-container {
    margin-top: 12px;
    .label-data,
    .data {
      font-size: 16px;
    }
  }

  hr {
    border-color: ${(props) => {
      if (props.tipo) {
        switch (props.tipo.toUpperCase()) {
          case 'PERFIL COMPORTAMENTAL':
            return 'var(--pink)';
          case 'PESQUISA DE PERCEPÇÃO':
            return 'var(--purple)';
          case 'VALORES ORGANIZACIONAIS':
            return 'var(--orange)';
          default:
            break;
        }
      }
      return 'var(--pink)';
    }};

    .ant-btn {
      margin-top: 12px;
    }

    margin: 0;
    padding: 0;
  }
`;
