import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root{
    --pink: #ef5472;
    --purple: #30318b;
    --orange: #ffb17f
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    -webkit-font-smoothing: antialiased;
    font-family: 'Segoe UI', sans-serif;
    /* background-color: rgba(0,0,0,0.8) !important; */
  }

  body,input,button{
    font: 16px 'Segoe UI', sans-serif;
  }

  #root{
    margin: 0 auto;
  }

  button{
    cursor: pointer;
  }
`;

export const noPadding = { padding: '0px' };

export const noMargin = { margin: '0px' };
