import styled from 'styled-components';

import { Wrapper as PesquisaWrapper } from './components/Pesquisa/style';

export const Container = styled.div`
  > .ant-col > .ant-row {
    .ant-col + .ant-col {
      margin-top: 32px;
    }
  }
`;

const PesquisaRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${PesquisaWrapper} {
    width: 100%;
    min-width: 100%;
  }

  @media (min-width: 768px) {
    ${PesquisaWrapper} {
      width: 33%;
      min-width: 33%;
    }
  }

  @media (min-width: 1200px) {
    ${PesquisaWrapper} {
      width: 25%;
      min-width: 25%;
    }
  }

  @media (min-width: 2200px) {
    ${PesquisaWrapper} {
      width: 12.5%;
      min-width: 12.5%;
    }
  }
`;

export const PesquisasPendentesRow = styled(PesquisaRow)`
  flex-wrap: wrap;
  width: 100%;
`;

export const PesquisasConcluidasRow = styled(PesquisaRow)`
  flex-wrap: wrap;
  overflow-x: hidden;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;
