import jwt from 'jsonwebtoken';

import store from 'redux/store';

import { createBrowserHistory } from 'history';

export const getToken = (): string | null => {
  const token =
    window.localStorage.getItem('access_token') ||
    window.sessionStorage.getItem('access_token');

  if (token) {
    return token;
  }

  return null;
};

export const getContaUrl = (): string | null => {
  const info = window.sessionStorage.getItem('info');
  if (info) {
    return JSON.parse(info).auth.conta_url;
  }
  return null;
};

export const getContaNome = (): string | null => {
  const info = window.sessionStorage.getItem('info');
  if (info) {
    return JSON.parse(info).auth.conta_nome;
  }
  return null;
};

export const getPessoaNome = (): string | null => {
  const info = window.sessionStorage.getItem('info');
  if (info) {
    return JSON.parse(info).auth.pes_nome;
  }
  return null;
};

export const login = async (
  token: string,
  rememberMe: boolean,
): Promise<void> => {
  store.dispatch({ type: 'DESTROY_REDUX' });
  if (rememberMe) window.localStorage.setItem('access_token', token);
  else window.sessionStorage.setItem('access_token', token);
};

export const logout = (): void => {
  window.localStorage.removeItem('access_token');
  window.sessionStorage.removeItem('access_token');
  window.sessionStorage.removeItem('info');
  store.dispatch({ type: 'DESTROY_REDUX' });

  if (window.location.pathname !== '/') {
    createBrowserHistory().push('/');
    window.location.reload();
  }
};

interface DecodedLoginToken {
  auth: {
    emp_id: number;
    conta_id: number;
    pes_id: number;
    conta_url: string;
  };
}

interface isAuthProps {
  conta?: string | null;
}

export const isAuthenticated = ({
  conta = getContaUrl(),
}: isAuthProps): boolean => {
  const token =
    window.localStorage.getItem('access_token') ||
    window.sessionStorage.getItem('access_token');

  const decoded = token ? (jwt.decode(token) as DecodedLoginToken) : null;

  if (
    token === null ||
    !decoded ||
    (decoded && conta && decoded.auth.conta_url !== conta)
  ) {
    logout();
    return false;
  }

  window.sessionStorage.setItem('info', JSON.stringify(jwt.decode(token)));

  return true;
};
