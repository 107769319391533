/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { hot } from 'react-hot-loader';
import {
  BrowserRouter,
  Switch,
  Route,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';

import { isAuthenticated, getPessoaNome } from 'services/auth';

import MainLayout from 'layouts/mainLayout';

import ContentLayout from 'layouts/contentLayout';

import Login from 'pages/Login';
import Pesquisas from 'pages/Pesquisas';

interface PrivateRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

interface PrivateRouteParams {
  conta: string;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  exact,
  path,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      exact={exact}
      path={path}
      render={(props: RouteComponentProps<PrivateRouteParams>) => {
        return isAuthenticated({ conta: props.match.params.conta }) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  exact: true,
};

const Routes: FC = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute
        exact
        path="/:conta/home"
        component={(props: RouteComponentProps) => (
          <MainLayout
            component={() => (
              <ContentLayout
                header={{
                  title: 'Pesquisas',
                  subtitle: `Olá ${getPessoaNome()}, estas são suas pesquisas.`,
                  breadcrumb: [{ name: 'Home', path: '/home' }],
                }}
                {...props}
              >
                <Pesquisas {...props} />
              </ContentLayout>
            )}
            {...props}
          />
        )}
      />
    </Switch>
  </BrowserRouter>
);

export default hot(module)(Routes);
