/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import { AppThunk } from 'redux/store';

import { LoginState, LoginSuccessData } from 'ts/login/types';
import { postLogin } from './api';

const initialState: LoginState = {
  email: null,
  password: null,
  remember: false,
  loading: false,
  error: null,
  accounts: [],
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
    },
    loginSuccess(state, action: PayloadAction<LoginSuccessData>) {
      const { contas } = action.payload;
      state.error = null;
      state.accounts = contas;
      state.loading = false;
    },
    loginError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      toast.error(action.payload);
    },
  },
});

export const { loginSuccess, loginError, loginStart } = loginSlice.actions;

export const fetchLogin = (login: string, password: string): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(loginStart());
    const data = await postLogin(login, password);
    const { contas, error } = data;
    if (error) {
      dispatch(loginError(error));
      return;
    }
    if (contas) dispatch(loginSuccess({ contas }));
  } catch (err) {
    dispatch(loginError(err.toString()));
  }
};

export default loginSlice.reducer;
